import React, { Component } from 'react';
import nova_icon from "../../assets/img/icons/novadao.png";

export default class ClaimButton extends Component {

  render() {
    return (
        <React.Fragment>
          <a href="https://novadao.io/defigirls/" id="mintButton" className="btn btn__two btnMint" target="_blank" rel="noreferrer"><img src={nova_icon} alt="icon" /> CLAIM MONTHLY REWARDS</a>
        </React.Fragment>
    )
  }
}
