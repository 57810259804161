import React, { Component } from 'react';
import Carousel from 'react-img-carousel';
import preview_0 from '../../assets/img/previews/preview_0_m.jpg';
import preview_1 from '../../assets/img/previews/preview_1_m.jpg';
import preview_2 from '../../assets/img/previews/preview_2_m.jpg';
import preview_3 from '../../assets/img/previews/preview_3_m.jpg';
import preview_4 from '../../assets/img/previews/preview_4_m.jpg';
import preview_5 from '../../assets/img/previews/preview_5_m.jpg';
import preview_6 from '../../assets/img/previews/preview_6_m.jpg';
import preview_7 from '../../assets/img/previews/preview_7_m.png';
require('react-img-carousel/lib/carousel.css');

const altTag = "DeFi Girl Preview Image"
const classTag = "carImg"

export default class PreviewCar extends Component {
    render() {
        return (
            <div style={{ marginTop: '40px' }}>
            <Carousel dots={false} arrows={false} cellPadding={12}
                      lazyLoad={false} infinite={true} width="89%"
                      autoplay={true} draggable={false} autoplaySpeed={2000}
                      initialSlide={1}>
                <img src={preview_7} alt={altTag}/>
                <img className={classTag} src={preview_0} alt={altTag}/>
                <img className={classTag} src={preview_1} alt={altTag}/>
                <img className={classTag} src={preview_2} alt={altTag}/>
                <img className={classTag} src={preview_3} alt={altTag}/>
                <img className={classTag} src={preview_4} alt={altTag}/>
                <img className={classTag} src={preview_5} alt={altTag}/>
                <img className={classTag} src={preview_6} alt={altTag}/>
            </Carousel >
            </div>
        )
    }
}