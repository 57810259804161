import discord_icon from "../../assets/img/icons/discord.png";
import twitter_icon from "../../assets/img/icons/twitter.png";
import magiceden_icon from "../../assets/img/icons/magiceden.png";
import nova_icon from "../../assets/img/icons/novadao.png";
import React, {Component} from "react";
import MintButton from "../banner/MintButton";



export default class SocialButtons extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="bottomSection">
                    <div className="banner__btn--wrap">
                        <a href="https://magiceden.io/collections/polygon/0xc6d960e677f9081dd78009e4973c49bbd1daac75" className="btn btn__alt opensea__btn" target="_blank" rel="noreferrer"><img src={magiceden_icon} alt="icon" />MAGIC EDEN</a>
                        <a href="https://novadao.io" className="btn btn__alt opensea__btn" target="_blank" rel="noreferrer"><img src={nova_icon} alt="icon" />NOVA DAO</a>
                        <a href="https://discord.gg/TeJfkwKt5s" className="btn btn__alt opensea__btn" target="_blank" rel="noreferrer"><img src={discord_icon} alt="icon" />DISCORD</a>
                        <a href="https://twitter.com/DefiGirlsNFT" className="btn btn__alt opensea__btn" target="_blank" rel="noreferrer"><img src={twitter_icon} alt="icon" />X / TWITTER</a>
                    </div>
                </div>
                <div id="YouFoundSomething">
                    11 1000 8128 8888 10000 94590 101987<br/>
                </div>
            </React.Fragment>
        )
    }
}