import React, { Component } from 'react';
import { isWalletConnected } from '../../config';
import {
  checkEarlyBirdHasMinted,
  checkEarlyBirdIsLive,
  checkPublicMint,
  checkEarlyBird,
  checkSupplyMinted,
  mint, checkTxnStatus
} from '../../lib/web3mint';
import CustomModal from '../modals/CustomModal';
import mint_icon from "../../assets/img/icons/mint.png";
import opensea_icon from "../../assets/img/icons/opensea.png";
import magiceden_icon from "../../assets/img/icons/magiceden.png";

export default class MintButton extends Component {

  canMint = true;

  constructor(props, canMint = true){
    super(props);
    this.state = {
      modal: false,
      success:false,
    }
    this.canMint = canMint;
  }

  async timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  render() {
    return (
        <React.Fragment>
          <a href="https://magiceden.io/collections/polygon/0xc6d960e677f9081dd78009e4973c49bbd1daac75" id="mintButton" className="btn btn__two btnMint" target="_blank" rel="noreferrer"><img src={magiceden_icon} alt="icon" /> BROWSE ON MAGIC EDEN</a>
        </React.Fragment>
    )
  }
}
