import React, { Component } from 'react';
import opensea_icon from '../../assets/img/icons/opensea.png';
import discord_icon from '../../assets/img/icons/discord.png';
import defi_logo from '../../assets/img/logo/defigirls_logo.png';

import MintButton from './MintButton';

export default class BannerContent extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="banner__content--wrap">
            <div className="logoWrap">
                <img className="defi_logo" src={defi_logo}/>
            </div>
            <p>A Trading & Utility focused Polygon DAO launched via Free Mint, with each NFT entitling holders to monthly yield rewards from our Gamma LP Pairs.</p><br/>
            <p>100% of all royalties are returned to the DeFi Girls DAO, managed under the <u><a href='https://novadao.io'>Nova DAO Ecosystem</a></u>.</p>
        </div>
      </React.Fragment>
    )
  }
}
