import React, {Component, useEffect, useState} from 'react';
import Sound from 'react-sound';
import beat from "../../assets/beat.mp3";

import MintButton from '../banner/MintButton';
import ClaimButton from '../banner/ClaimButton';
import {
    checkEarlyBird,
    checkEarlyBirdHasMinted,
    checkEarlyBirdIsLive,
    checkPublicMint,
    checkSupplyMinted
} from '../../lib/web3mint';

export default class BottomContent extends Component {

    async timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    render() {
        return (
            <React.Fragment>
                <div className="bottomSection">
                    <div className="banner__btn--wrap">
                        <MintButton/>
                    </div>
                    <div className="banner__btn--wrap">
                        <ClaimButton/>
                    </div>
                </div>
                <div className="bottomSectionPar">
                    <p id="mintEl">DeFi Girls is available exclusively on Polygon: 0xc6d960e677f9081dd78009e4973c49bbd1daac75</p>

                    <p><a href="https://polygonscan.com/address/0x754bbb703eeada12a6988c0e548306299a263a08" target="_blank" rel="noreferrer">DAO Address: DeFiGirlsDAO.eth</a>
                    </p>

                </div>
            </React.Fragment>
        )
    }
}
