import React, { Component } from 'react';
import banner_img from '../../assets/img/banner/banner__img.png';
import PreviewCar from '../carousel/PreviewCar.js'

export default class BannerImage extends Component {
  render() {
    return (
      <React.Fragment>
            <PreviewCar/>
      </React.Fragment>
    )
  }
}
